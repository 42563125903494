import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
  Box,
  Heading,
  Link
} from "@chakra-ui/react";


interface NavListItem {
  label: string
  path: string
}

type NavListType = NavListItem[];

interface Props {
  heading: string
  navList: NavListType
}


export const LinksColumn: React.FC<Props> = ( props ) => {
  return (
    <Box>
      <Heading
        as="h3"
        fontSize="20px"
      >
        { props.heading }
      </Heading>
      { props.navList.map(( item, _index: number ) => {
        return (
          <Box
            key={ _index }
            my="20px"
          >
            <Link
              as={ GatsbyLink }
              to={ item.path }
              _hover={{
                color: "primary.900",
                textDecoration: "none"
              }}
            >
              { item.label }
            </Link>
          </Box>
        )
      })}
    </Box>
  )
}
