import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: object | string
  h?: object | string
  color?: string
}


export const StackIcon: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 33 34" { ...props }>
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2455 0.302095C16.0352 -0.100698 16.9648 -0.100698 17.7545 0.302094L31.4493 7.28692C33.5169 8.34144 33.5169 11.3512 31.4493 12.4057L29.4544 13.4232L31.4493 14.4406C33.5169 15.4951 33.5169 18.5049 31.4493 19.5594L29.4544 20.5769L31.4493 21.5943C33.5169 22.6488 33.5169 25.6586 31.4493 26.7131L17.7545 33.6979C16.9648 34.1007 16.0352 34.1007 15.2455 33.6979L1.55067 26.7131C-0.51689 25.6586 -0.516887 22.6488 1.55067 21.5943L3.54556 20.5769L1.55067 19.5594C-0.51689 18.5049 -0.516887 15.4951 1.55067 14.4406L3.54556 13.4232L1.55067 12.4057C-0.516892 11.3512 -0.516887 8.34144 1.55067 7.28692L15.2455 0.302095ZM7.3238 12.1509C7.3147 12.1462 7.30555 12.1415 7.29635 12.137L2.80518 9.8463L16.5 2.86148L30.1948 9.8463L25.7034 12.1371C25.6944 12.1416 25.6854 12.1461 25.6764 12.1508L16.5 16.8311L7.3238 12.1509ZM6.68185 15.0228L2.80518 17L7.29618 19.2906L7.32397 19.3047L16.5 23.9848L25.6764 19.3046L25.7035 19.2907L30.1948 17L26.3181 15.0228L17.7545 19.3905C16.9648 19.7933 16.0352 19.7933 15.2455 19.3905L6.68185 15.0228ZM2.80518 24.1537L6.68185 22.1765L15.2455 26.5442C16.0352 26.947 16.9648 26.947 17.7545 26.5442L26.3181 22.1765L30.1948 24.1537L16.5 31.1385L2.80518 24.1537Z"
      />
    </Icon>
  )
}
