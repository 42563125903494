import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: object | string
  h?: object | string
  color?: string
}


export const FastActionIcon: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 61 61" { ...props }>
      <path
        fill="currentColor"
        d="M0 28.026C0.152642 26.9459 0.2818 25.8659 0.457926 24.7976C1.27984 19.961 3.20548 15.5822 6.22309 11.72C10.8376 5.81504 16.8023 2.03496 24.1761 0.591014C31.2681 -0.794234 38.0196 0.262311 44.3953 3.69021C44.6301 3.81934 44.8767 3.94848 45.1115 4.07761C45.1468 4.08935 45.182 4.08935 45.2759 4.11283C46.3914 2.98585 47.5421 1.84713 48.681 0.696668C49.1977 0.180135 49.773 -0.125089 50.5245 0.0744807C51.3581 0.28579 51.8748 0.931456 51.8865 1.89409C51.91 4.62936 51.91 7.36464 51.8865 10.0999C51.8748 11.2386 51.1233 11.9547 49.9843 11.9547C47.2838 11.9665 44.5949 11.9547 41.8943 11.9547C41.0607 11.9547 40.4031 11.6378 40.0861 10.8278C39.7808 10.053 40.0039 9.38381 40.5793 8.80859C41.2603 8.13944 41.9296 7.47029 42.6341 6.76593C40.544 5.60373 38.407 4.78197 36.1526 4.27718C28.274 2.51627 20.9706 3.88978 14.4423 8.65597C8.27789 13.1522 4.69667 19.2801 3.7456 26.8872C2.28963 38.5327 8.80626 49.603 19.362 54.2518C23.319 55.9892 27.4755 56.8227 31.7965 56.4001C42.8689 55.3435 50.501 49.5795 54.7632 39.3545C55.9961 36.3844 56.4423 33.25 56.501 30.0451C56.5245 28.6716 57.8865 27.8499 59.0724 28.4838C59.7182 28.8242 60.0117 29.376 60.0117 30.1156C60 35.68 58.591 40.8806 55.6556 45.6116C51.7104 51.9978 46.1448 56.3766 38.9824 58.6775C36.8806 59.3466 34.7202 59.7693 32.5127 59.9219C32.3366 59.9336 32.1722 59.9923 32.0078 60.0275C30.681 60.0275 29.3542 60.0275 28.0274 60.0275C27.863 59.9923 27.6869 59.9336 27.5225 59.9219C26.4658 59.781 25.3855 59.7106 24.3405 59.4993C16.9667 58.0318 10.955 54.3222 6.31703 48.4173C3.26419 44.5315 1.31507 40.141 0.481409 35.2574C0.2818 34.1657 0.152642 33.0856 0 32.0056C0 30.6791 0 29.3525 0 28.026Z"
      />
      <path
        fill="currentColor"
        d="M30.5042 29.305C30.786 29.305 30.9974 29.305 31.2087 29.305C34.1207 29.305 37.0209 29.305 39.9328 29.305C41.013 29.305 41.7997 30.0446 41.7997 31.0425C41.8115 32.052 41.0248 32.8034 39.9563 32.8034C36.2459 32.8034 32.5473 32.8034 28.8369 32.8034C27.7215 32.8034 27.0052 32.0755 27.0052 30.972C26.9935 27.2624 27.0052 23.5645 27.0052 19.8548C27.0052 18.7748 27.7567 18 28.7547 18C29.7645 18 30.5042 18.7748 30.5042 19.8548C30.5042 22.7662 30.5042 25.6658 30.5042 28.5772C30.5042 28.8002 30.5042 29.0115 30.5042 29.305Z"
      />
    </Icon>
  )
}
