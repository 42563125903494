import * as React from "react";
import {
  Grid
} from "@chakra-ui/react";

import {
  BoxItem,
  ListItem
} from "./Items";


interface NavListItem {
  label: string
  path: string
}

type NavListType = NavListItem[];

interface Props {
  template: string
  navList: NavListType

  display: object | string
}


export const SiteNav: React.FC<Props> = ( props ) => {
  const navItems = props.navList.map( ( node: any, _index: number ) => {
    switch ( props.template ) {
      case 'box':
        return (
          <BoxItem
            key={ _index }
            node={ node }
          />
        )
      case 'list':
        return (
          <ListItem
            key={ _index }
            node={ node }
          />
        )
      default:
        return null;
    }
  })

  return (
    <Grid
      as="nav"
      display={ props.display }
      templateColumns={{
        base: '1fr',
        lg: 'repeat(6, 1fr)'
      }}
      gap={{
        base: '16px',
        lg: 12
      }}
      pb="20px"
    >
      { navItems }
    </Grid>
  )
}
