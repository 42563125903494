import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: object | string
  h?: object | string
  color?: string
}


export const TwentyFourSevenIcon: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 60 60" { ...props }>
      <path
        fill="currentColor"
        d="M60 34.0312C59.7656 35.0859 59.5781 36.1641 59.3086 37.207C57.4805 44.2734 53.5313 49.8984 47.6485 54.1758C43.6055 57.1172 39.0938 58.9453 34.1367 59.5898C26.3555 60.6094 19.1836 58.9102 12.7852 54.3398C6.2461 49.6758 2.1211 43.3711 0.632823 35.4844C-0.890615 27.375 0.773447 19.8633 5.39064 13.0195C6.07032 12.0234 6.83204 11.0859 7.62892 10.0312C7.38282 10.0312 7.16017 10.043 6.94923 10.0312C5.97657 10.0195 5.25001 9.25781 5.25001 8.28516C5.26173 7.32422 5.98829 6.5625 6.94923 6.5625C8.66017 6.55078 10.3828 6.55078 12.0938 6.5625C13.0547 6.57422 13.7813 7.3125 13.793 8.28516C13.8047 9.92578 13.8047 11.5547 13.793 13.1953C13.7813 14.1797 13.0078 14.9531 12.0586 14.9648C11.1094 14.9766 10.3242 14.2031 10.2891 13.2305C10.2891 13.043 10.2891 12.8438 10.1719 12.5859C9.6797 13.2188 9.15235 13.8281 8.6836 14.4727C6.09376 18.0352 4.39454 21.9961 3.89064 26.3555C2.7422 36.2109 6.03517 44.3555 13.6992 50.6602C17.4024 53.707 21.7149 55.5 26.4609 56.1211C33.7266 57.0703 40.3008 55.2656 46.1133 50.8594C51.2461 46.9805 54.5977 41.8711 56.0625 35.5664C56.2266 34.8516 56.3438 34.1133 56.461 33.3867C56.625 32.4375 57.1289 31.8398 57.9258 31.7109C58.7695 31.5703 59.4492 31.9687 59.8828 32.8477C59.9063 32.8945 59.9531 32.9414 59.9883 32.9766C60 33.3281 60 33.6797 60 34.0312Z"
      />
      <path
        fill="currentColor"
        d="M60 26.9064C59.6484 27.5978 59.2031 28.1485 58.3476 28.1837C57.4336 28.2188 56.6367 27.6329 56.5078 26.7306C56.1797 24.3517 55.3359 22.1485 54.457 19.9454C54.2812 19.5001 54.1992 18.961 54.2695 18.4923C54.375 17.7775 55.0195 17.2735 55.6758 17.2032C56.4726 17.1212 57.1992 17.4493 57.5039 18.1759C58.5351 20.5548 59.4609 22.9689 59.8711 25.5353C59.8945 25.6407 59.9414 25.7462 59.9883 25.8517C60 26.2032 60 26.5548 60 26.9064Z"
      />
      <path
        fill="currentColor"
        d="M34.1486 0C35.5197 0.351562 36.9025 0.65625 38.2502 1.06641C39.3635 1.40625 40.4416 1.85156 41.5197 2.28516C42.5275 2.69531 42.9494 3.70312 42.5627 4.62891C42.176 5.55469 41.2033 5.89453 40.1721 5.55469C38.2619 4.91016 36.34 4.30078 34.4181 3.70312C34.1017 3.60937 33.7736 3.58594 33.4455 3.52734C32.5549 3.38672 31.9572 2.84766 31.8283 2.07422C31.6994 1.25391 32.0861 0.550781 32.9064 0.140625C32.9767 0.105469 33.0353 0.0585937 33.0939 0.0117187C33.4455 0 33.797 0 34.1486 0Z"
      />
      <path
        fill="currentColor"
        d="M27.0117 0C27.7031 0.351562 28.2539 0.796875 28.289 1.65234C28.3242 2.56641 27.7383 3.36328 26.8359 3.49219C24.4453 3.82031 22.1953 4.60547 19.9805 5.51953C19.1484 5.85938 18.4219 5.71875 17.8828 5.16797C17.0156 4.25391 17.3203 2.83594 18.5156 2.32031C20.8594 1.3125 23.2383 0.46875 25.7578 0.09375C25.8281 0.0820312 25.8984 0.0351562 25.9687 0C26.3203 0 26.6719 0 27.0117 0Z"
      />
      <path
        fill="currentColor"
        d="M30.0469 8.77734C41.7188 8.77734 51.2344 18.293 51.2227 29.9648C51.2227 41.6016 41.707 51.1172 30.0703 51.1172C18.3984 51.1172 8.88282 41.6016 8.89454 29.9297C8.89454 18.2812 18.4102 8.77734 30.0469 8.77734ZM12.3984 29.9414C12.3984 39.6445 20.3555 47.6016 30.0586 47.6016C39.7617 47.6016 47.7188 39.6445 47.7188 29.9414C47.7188 20.2383 39.7617 12.2812 30.0586 12.2812C20.3555 12.2812 12.3984 20.2383 12.3984 29.9414Z"
      />
      <path
        fill="currentColor"
        d="M47.0858 5.625C47.449 5.78906 47.8475 5.89453 48.1639 6.12891C50.2967 7.72266 52.1482 9.59766 53.7654 11.7188C54.41 12.5625 54.2811 13.6641 53.4959 14.2617C52.7107 14.8594 51.6561 14.7305 51.0115 13.8984C49.535 12 47.8475 10.3125 45.949 8.83594C45.3045 8.33203 45.117 7.57031 45.3865 6.84375C45.6444 6.14063 46.2655 5.70703 47.0858 5.625Z"
      />
      <path
        fill="currentColor"
        d="M21.9376 35.2032C23.4728 35.2032 24.9962 35.1915 26.5314 35.2032C27.4455 35.2032 28.172 35.8243 28.2892 36.6797C28.4181 37.5704 27.8791 38.4024 27.0002 38.6485C26.8009 38.7071 26.5783 38.7071 26.3673 38.7071C24.1525 38.7071 21.9259 38.7071 19.7111 38.7071C18.4689 38.7071 17.7658 38.004 17.7775 36.7383C17.8009 34.629 18.7619 32.9649 20.3087 31.6172C21.2228 30.8321 22.1955 30.1172 23.133 29.3555C23.9298 28.6993 24.5861 27.961 24.7502 26.8711C24.9142 25.7579 24.2462 24.7969 23.1916 24.668C22.2306 24.5508 21.3634 25.3477 21.2814 26.4376C21.1994 27.504 20.4728 28.1954 19.465 28.1602C18.4806 28.1368 17.7775 27.3633 17.7775 26.3086C17.7775 23.5196 20.1564 21.1641 22.9806 21.1524C25.8752 21.1407 28.2423 23.4258 28.3009 26.2501C28.3478 28.4532 27.4689 30.2227 25.8634 31.6524C24.9142 32.4844 23.8947 33.2344 22.9103 34.0313C22.5353 34.336 22.172 34.6641 21.8087 34.9805C21.8556 35.0743 21.8908 35.1329 21.9376 35.2032Z"
      />
      <path
        fill="currentColor"
        d="M38.8359 31.6994C37.0898 31.6994 35.4375 31.6994 33.7852 31.6994C32.5195 31.6994 31.8281 31.0197 31.8281 29.7658C31.8281 27.5509 31.8281 25.3244 31.8281 23.1095C31.8281 21.9611 32.5195 21.1876 33.5625 21.1759C34.6289 21.1642 35.332 21.9376 35.332 23.1212C35.332 24.797 35.332 26.4611 35.332 28.1603C36.5039 28.1603 37.6289 28.1603 38.8477 28.1603C38.8477 27.9611 38.8477 27.7619 38.8477 27.5626C38.8477 26.0392 38.8477 24.5275 38.8477 23.004C38.8477 21.9259 39.5977 21.1642 40.6172 21.1759C41.6133 21.1876 42.3398 21.9376 42.3398 22.9923C42.3398 27.6329 42.3398 32.2619 42.3398 36.9025C42.3398 37.9454 41.6016 38.7072 40.6172 38.7189C39.5977 38.7306 38.8477 37.9689 38.8359 36.8908C38.8242 35.1798 38.8359 33.4689 38.8359 31.6994Z"
      />
    </Icon>
  )
}
