import * as React from "react";
import {
  Link,
  Text
} from "@chakra-ui/react";


interface CopyrightProps {}


export const Copyright: React.FC<CopyrightProps> = () => {
  return (
    <Text
      fontSize="12px"
      textAlign={{
        base: 'center',
        lg: 'left'
      }}
    >
      © { new Date().getFullYear() } Drobot Construction. All Rights Reserved.&nbsp;
      <Link
        display={{
          base: 'block',
          lg: 'inline'
        }}
        href="https://explore.agency"
        isExternal
        _hover={{
          color: "primary.900",
          textDecoration: "none"
        }}
      >
        Developed by Explore Agency&trade;.
      </Link>
    </Text>
  )
}
