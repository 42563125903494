import * as React from "react";

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  BurgerIcon,
  ChatIcon,
  CleaningIcon,
  ConcreteTruckIcon,
  FastActionIcon,
  JackhammerIcon,
  LicenseIcon,
  StackIcon,
  SumpPumpIcon,
  TwentyFourSevenIcon,
  VerifiedCheckmarkIcon,
  WaterproofIcon
} from "./Icons";


interface Props {
  icon: string
  w?: string
  h?: string
  color?: string
  transition?: string
  _hover?: object
}


export const IconFactory: React.FC<Props> = ( props ) => {
  switch ( props.icon ) {
    case 'arrow-left':
      return <ArrowLeftIcon { ...props } />;
    case 'arrow-right':
      return <ArrowRightIcon { ...props } />;
    case 'burger':
      return <BurgerIcon { ...props } />;
    case 'chat':
      return <ChatIcon { ...props } />;
    case 'cleaning':
      return <CleaningIcon { ...props } />;
    case 'concrete':
      return <ConcreteTruckIcon { ...props } />;
    case 'fast-action':
      return <FastActionIcon { ...props } />;
    case 'jackhammer':
      return <JackhammerIcon { ...props } />;
    case 'license':
      return <LicenseIcon { ...props } />;
    case 'stack':
      return <StackIcon { ...props } />;
    case 'sump-pump':
      return <SumpPumpIcon { ...props } />;
    case 'twenty-four-seven':
      return <TwentyFourSevenIcon { ...props } />;
    case 'verified':
      return <VerifiedCheckmarkIcon { ...props } />;
    case 'waterproof':
      return <WaterproofIcon { ...props } />;
    default:
      return null;
  }
}
