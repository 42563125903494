import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
  EmailIcon
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  HStack,
  IconButton,
  Link
} from "@chakra-ui/react";

import { SiteNav } from "../Navigation";
import { DrobotLogo } from "../ScalableVectorGraphics";

import { Burger } from "./Burger";


export const Header: React.FC = () => {

  return (
    <Box
      as="header"
      position="fixed"
      w="100%"
      bg="background.aliceblue"
      zIndex={ 1000 }
    >
      <Container
        maxW={{
          base: '100%',
          sm: '640px',
          md: '768px',
          lg: '1024px',
          xl: '1280px'
        }}
        w="100%"
        padding="0 1rem"
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          h={{
            base: '40px',
            lg: '42px'
          }}
          mt="24px"
          mb="26px"
        >
          <Link
            as={ GatsbyLink }
            to="/"
            display={{
              base: 'none',
              sm: 'inline'
            }}
            _hover={{
              opacity: '0.6'
            }}
          >
            <DrobotLogo
              w={ 230 }
              h={ 29 }
            />
          </Link>
          <Link
            as={ GatsbyLink }
            to="/"
            display={{
              base: 'inline',
              sm: 'none'
            }}
            _hover={{
              opacity: '0.6'
            }}
          >
            <DrobotLogo
              w={ 190 }
              h={ 24 }
            />
          </Link>
          <HStack
            display={{
              base: 'none',
              lg: 'flex'
            }}
            spacing="16px"
          >
            <IconButton
              as={ Link }
              href="mailto:info@drobotconstruction.com"
              isExternal
              aria-label="Email us"
              icon={ <EmailIcon /> }
              w="42px"
              h="42px"
              bg="transparent"
              border="1px"
              borderRadius="50%"
              borderColor="primary.900"
              color="primary.900"
              transition="all 0.3s ease-in-out"
              _hover={{
                bg: 'primary.900',
                color: 'white'
              }}
            />
            <Button
              as={ Link }
              href="tel:+16477216065"
              isExternal
              variant="outline"
              h="42px"
              bg="transparent"
              borderRadius="21px"
              borderColor="primary.900"
              color="primary.900"
              fontWeight="500"
              transition="all 0.3s ease-in-out"
              _hover={{
                bg: 'primary.900',
                color: 'white',
                textDecoration: 'none'
              }}
            >
              +1 647 721 6065
            </Button>
          </HStack>
          <Burger />
        </Flex>
        <Divider
          display={{
            lg: 'none'
          }}
          borderColor="border.gainsboro"
        />
        <SiteNav
          template="box"
          navList={[
            {
              label: 'Our Story',
              path: '/about'
            },
            {
              label: 'Services',
              path: '/services'
            },
            {
              label: 'Our Work',
              path: '/portfolio'
            },
            {
              label: 'Careers',
              path: '/careers'
            },
            {
              label: 'FAQ',
              path: '/faq'
            },
            {
              label: 'Get In Touch',
              path: '/contact'
            }
          ]}
          display={{
            base: 'none',
            lg: 'grid'
          }}
        />
      </Container>
    </Box>
  )
}
