import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: object | string
  h?: object | string
  color?: string
}


export const CloseIcon: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 10 10" { ...props }>
      <path
        fill="currentColor"
        d="M9.66732 1.01595L8.98486 0.333496L5.00065 4.32251L1.01644 0.333496L0.333984 1.01595L4.323 5.00016L0.333984 8.98437L1.01644 9.66683L5.00065 5.67781L8.98486 9.66683L9.66732 8.98437L5.6783 5.00016L9.66732 1.01595Z"
      />
    </Icon>
  )
}
