import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  HStack,
  Link,
  List,
  ListIcon,
  ListItem,
  VStack
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";

import { Modal } from "../Modal";
import { DrobotLogo } from "../ScalableVectorGraphics";

import { CallToAction } from "./CallToAction";
import { Copyright } from "./Copyright";
import {
  LinksColumn,
  SocialsColumn
} from "./Columns";


const Footer: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box
      as="footer"
    >
      <Container
        maxW={{
          base: '100%',
          sm: '640px',
          md: '768px',
          lg: '1024px',
          xl: '1280px'
        }}
        w="100%"
        margin="0 auto"
        padding="0 1rem"
      >
        <CallToAction />
        <Grid
          templateColumns={{
            base: '1fr',
            lg: '2fr 3fr 1fr',
            xl: '3fr 3fr 1fr'
          }}
          gap={ 8 }
          mb="80px"
        >
          <VStack
            justify="flex-start"
            align={{
              base: 'center',
              lg: 'flex-start'
            }}
            spacing="20px"
          >
            <Box
              h="29px"
              margin="0"
            >
              <DrobotLogo
                w={ 230 }
                h={ 29 }
              />
            </Box>
            <List
              spacing="20px"
            >
              <ListItem>
                <ListIcon
                  as={ EmailIcon }
                  color="primary.900"
                  w="21px"
                  h="21px"
                />
                <Link
                  href="mailto:info@drobotconstruction.com"
                  isExternal
                  _hover={{
                    color: "primary.900",
                    textDecoration: "none"
                  }}
                >
                  info@drobotconstruction.com
                </Link>
              </ListItem>
              <ListItem>
                <ListIcon
                  as={ PhoneIcon }
                  color="primary.900"
                  w="21px"
                  h="21px"
                />
                <Link
                  href="tel:+16477216065"
                  isExternal
                  display={{
                    base: 'inline',
                    md: 'none'
                  }}
                  _hover={{
                    color: "primary.900",
                    textDecoration: "none"
                  }}
                >
                  +1 647 721 6065
                </Link>
                <Box
                  display={{
                    base: 'none',
                    md: 'inline'
                  }}
                >
                  <Button
                    variant="link"
                    onClick={ onOpen }
                    color="text.primary"
                    fontWeight={ 400 }
                    _hover={{
                      color: "primary.900",
                      textDecoration: "none"
                    }}
                  >
                    +1 647 721 6065
                  </Button>
                  <Modal
                    isOpen={ isOpen }
                    onClose={ onClose }
                    layout="call-now"
                  />
                </Box>
              </ListItem>
            </List>
          </VStack>
          <Grid
            templateColumns={{
              base: '1fr 1fr',
              lg: '1fr 1fr 1fr'
            }}
            gap={ 8 }
          >
            <LinksColumn
              heading="Contact Us"
              navList={[
                {
                  label: "Home",
                  path: "/"
                },
                {
                  label: "Our Story",
                  path: "/about"
                },
                {
                  label: "Services",
                  path: "/services"
                },
                {
                  label: "Our Work",
                  path: "/portfolio"
                },
                {
                  label: "FAQ",
                  path: "/faq"
                }
              ]}
            />
            <LinksColumn
              heading="Services"
              navList={[
                {
                  label: "Underpinning",
                  path: "/services/underpinning"
                },
                {
                  label: "Waterproofing",
                  path: "/services/waterproofing"
                },
                {
                  label: "Concrete",
                  path: "/services/concrete"
                },
                {
                  label: "Sump Pump",
                  path: "/services/sump-pump"
                }
              ]}
            />
            <LinksColumn
              heading="We're Hiring!"
              navList={[
                {
                  label: "Careers",
                  path: "/careers"
                }
              ]}
            />
          </Grid>
          <SocialsColumn
            heading="Follow Us"
          />
        </Grid>
        <Flex
          flexDirection={{
            base: 'column-reverse',
            lg: 'row'
          }}
          justifyContent={{
            base: 'center',
            lg: 'space-between'
          }}
          alignItems="center"
          gap={{
            base: '24px',
            lg: '0'
          }}
          py="24px"
          borderTop="1px"
          borderColor="border.gainsboro"
        >
          <Copyright />
          <HStack
            spacing="32px"
          >
            <Box>
              <Link
                as={ GatsbyLink }
                to="/legal/privacy-policy"
                fontSize="12px"
                _hover={{
                  color: "primary.900",
                  textDecoration: "none"
                }}
              >
                Privacy Policy
              </Link>
            </Box>
            <Box>
              <Link
                as={ GatsbyLink }
                to="/legal/terms-of-service"
                fontSize="12px"
                _hover={{
                  color: "primary.900",
                  textDecoration: "none"
                }}
              >
                Terms of Service
              </Link>
            </Box>
          </HStack>
        </Flex>
      </Container>
    </Box>
  )
}


export default Footer;
