import * as React from "react";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import {
  Box,
  Button,
  Heading,
  Image,
  Link,
  Stack,
  Text,
  VStack
} from "@chakra-ui/react";


interface Props {
  heading: string
  description?: string | undefined
  image: IGatsbyImageData
}


export const ServiceHero: React.FC<Props> = ( props ) => {
  return (
    <Stack
      direction={{
        base: 'column',
        lg: 'row'
      }}
      justify="space-between"
      align="center"
      spacing={{
        base: '32px',
        lg: '0'
      }}
      pt="32px"
    >
      <Box
        minW={{
          lg: '550px',
          xl: '771px'
        }}
        w={{
          lg: '550px',
          xl: '771px'
        }}
        background={{
          base: 'linear-gradient(to right, white 0%, white 90%, #F1F5FB 90%, #F1F5FB 100%)',
          sm: 'linear-gradient(to right, white 0%, white 75%, #F1F5FB 75%, #F1F5FB 100%)'
        }}
        padding={{
          base: '24px 0',
          sm: '24px',
          md: '100px',
          lg: '50px',
          xl: '100px'
        }}
      >
        <Image
          as={ GatsbyImage }
          image={ props.image }
        />
      </Box>
      <VStack
        justify="flex-start"
        align="flex-start"
        spacing={ 8 }
        w={{
          xl: '510px'
        }}
      >
        <Heading
          as="h1"
          fontSize="48px"
          fontWeight={ 700 }
        >
          { props.heading }
        </Heading>
        <Text
          fontSize="16px"
          fontWeight={ 400 }
        >
          { props.description }
        </Text>
        <Box>
          <Button
            as={ Link }
            href="tel:+16477216065"
            isExternal
            variant="solid"
            h="42px"
            bg="primary.900"
            border="1px"
            borderRadius="21px"
            borderColor="primary.900"
            color="white"
            fontWeight={ 500 }
            _hover={{
              bg: "transparent",
              color: "primary.900",
              textDecoration: "none"
            }}
          >
            +1 647 721 6065
          </Button>
        </Box>
      </VStack>
    </Stack>
  )
}
