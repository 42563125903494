import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: object | string
  h?: object | string
  color?: string
}


export const ArrowLeftIcon: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 8 14" { ...props }>
      <path
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
        d="M6.66699 12.3333L1.33366 6.99992L6.66699 1.66658"
      />
    </Icon>
  )
}
