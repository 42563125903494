import * as React from "react";
import { useState } from "react";
import { PhoneIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  IconButton,
  Link,
  VStack
} from "@chakra-ui/react";

import {
  SiteNav,
  Socials
} from "../Navigation";
import {
  BurgerIcon,
  CloseIcon
} from "../ScalableVectorGraphics";


export const Burger: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState( false );

  const toggleMenu = () => {
    setMenuOpen( !menuOpen );
  };

  return (
    <>
      <IconButton
        aria-label="Open menu"
        icon={ menuOpen ? <CloseIcon /> : <BurgerIcon /> }
        display={{
          lg: 'none'
        }}
        bg="transparent"
        border="1px"
        borderRadius="50%"
        borderColor="primary.900"
        color="primary.900"
        transition="all 0.3s ease-in-out"
        onClick={ toggleMenu }
      />
      <Box
        position="fixed"
        top="91px"
        right={ 0 }
        bottom={ 0 }
        left={ 0 }
        visibility={ menuOpen ? 'visible' : 'hidden' }
        w="100%"
        h="100vh"
        background="white"
        opacity={ menuOpen ? '1' : '0' }
        transition="all 0.5s ease-in-out"
        zIndex={ 900 }
      >
        <Container
          maxW={{
            base: '100%',
            sm: '640px',
            md: '768px',
            lg: '1024px',
            xl: '1280px',
            '2xl': '1536px'
          }}
          w="100%"
          padding="2rem 1rem"
        >
          <SiteNav
            template="list"
            navList={[
              {
                label: 'Our Story',
                path: '/about'
              },
              {
                label: 'Services',
                path: '/services'
              },
              {
                label: 'Our Work',
                path: '/portfolio'
              },
              {
                label: 'Careers',
                path: '/careers'
              },
              {
                label: 'FAQ',
                path: '/faq'
              },
              {
                label: 'Get In Touch',
                path: '/contact'
              }
            ]}
            display={{
              base: 'grid',
              lg: 'none'
            }}
          />
          <Flex
            justifyContent="space-between"
            alignItems="center"
            w="100%"
            padding="2rem 0"
          >
            {/*
            <IconButton
              aria-label="Call now"
              as={ Link }
              href="tel:+16477216065"
              icon={ <PhoneIcon /> }
              w="100px"
              h="32px"
              background="transparent"
              color="primary.900"
              border="1px"
              borderColor="primary.900"
              borderRadius="16px"
              _hover={{
                background: 'transparent',
                color: 'primary.900'
              }}
              _focus={{
                boxShadow: 'none !important'
              }}
            />
            */}
            <Socials />
          </Flex>
          <Divider />
          <VStack
            align="flex-start"
            spacing={ 4 }
            padding="2rem 0"
          >
            <Box>
              <Button
                as={ Link }
                href="mailto:info@drobotconstruction.com"
                variant="link"
                color="primary.900"
                fontSize="18px"
                fontWeight={ 400 }
                lineHeight="21px"
                _hover={{
                  opacity: '0.6',
                  textDecoration: 'none'
                }}
                _focus={{
                  boxShadow: 'none !important'
                }}
              >
                info@drobotconstruction.com
              </Button>
            </Box>
            <Box>
              <Button
                as={ Link }
                href="tel:+16477216065"
                variant="link"
                color="primary.900"
                fontSize="18px"
                fontWeight={ 400 }
                lineHeight="21px"
                _hover={{
                  opacity: '0.6',
                  textDecoration: 'none'
                }}
                _focus={{
                  boxShadow: 'none !important'
                }}
              >
                +1 647 721 6065
              </Button>
            </Box>
          </VStack>
        </Container>
      </Box>
    </>
  )
}
