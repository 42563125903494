import * as React from "react";
import {
  Modal as ChakraModal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay
} from "@chakra-ui/react";

import {
  CallNow
} from "./Layouts";


interface Props {
  isOpen: boolean
  onClose: any
  layout: string
}


export const Modal: React.FC<Props> = ( props ) => {
  const modalLayout = [1].map( ( _index: number ) => {
    switch ( props.layout ) {
      case 'call-now':
        return (
          <CallNow
            key={ _index }
          />
        );
      default:
        return null;
    };
  });

  return (
    <ChakraModal
      isOpen={ props.isOpen }
      onClose={ props.onClose }
      motionPreset="slideInBottom"
      size="lg"
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        position="relative"
      >
        <ModalCloseButton />
        { modalLayout }
      </ModalContent>
    </ChakraModal>
  )
}
