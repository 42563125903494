import * as React from "react";
import {
  Box,
  Heading
} from "@chakra-ui/react";

import { Socials } from "../../Navigation";


interface Props {
  heading: string
}


export const SocialsColumn: React.FC<Props> = ( props ) => {
  return (
    <Box>
      <Heading
        as="h3"
        pb="20px"
        fontSize="20px"
      >
        { props.heading }
      </Heading>
      <Socials />
    </Box>
  )
}