import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w: number | string
  h: number | string
}


export const DrobotLogo: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 230 29" { ...props }>
      <path
        fill="black"
        d="M50.0357 0H70.0786C72.4869 0 74.429 0.236499 75.9051 0.630663C77.3811 1.02483 78.391 1.73432 79.0902 2.68032C79.7894 3.62631 80.1001 4.88764 80.1001 6.4643C80.1001 7.25263 80.0224 7.96212 79.9447 8.67162L79.7894 9.61761C79.5563 11.1154 79.2456 12.2979 78.7794 13.2439C78.3133 14.1899 77.7695 14.9783 76.9927 15.5301C76.2158 16.0819 75.2059 16.4761 73.9629 16.7914V16.9491C74.9728 17.1067 75.7497 17.3432 76.2935 17.6586C76.8373 17.9739 77.3034 18.3681 77.5365 18.9199C77.7695 19.4717 77.9249 20.1812 77.9249 21.1272C77.9249 22.0732 77.8472 22.9404 77.6918 23.8864L77.0704 28.0645H69.3795L69.7679 25.3054C69.9233 24.2805 70.001 23.4922 70.001 23.0192C70.001 22.0732 69.7679 21.4426 69.3795 20.9696C68.991 20.5754 68.2919 20.2601 67.282 20.1812C66.272 20.0236 64.7183 19.9447 62.6208 19.9447H54.9299L53.7646 28.0645H46.0737L50.0357 0ZM65.2621 14.1111C67.0489 14.1111 68.3696 14.0323 69.2241 13.7958C70.0786 13.5593 70.7001 13.2439 71.0109 12.6921C71.3216 12.1403 71.6324 11.3519 71.8654 10.1694L71.9431 9.61761C72.0208 9.14462 72.0208 8.75045 72.0208 8.51396C72.0208 7.80446 71.8654 7.17379 71.477 6.85846C71.0886 6.4643 70.5448 6.2278 69.7679 6.07013C68.991 5.91247 67.8258 5.91247 66.4274 5.91247H56.8721L55.7845 14.1111H65.2621Z"
      />
      <path
        fill="black"
        d="M89.8455 28.3591C87.6951 27.8785 86.1591 27.0773 85.2375 25.7956C84.3159 24.5138 83.7783 22.6713 83.7783 20.1878C83.7783 18.826 83.9319 17.0635 84.2391 14.9807L84.4695 13.6188C85.0071 9.37293 85.8519 6.32873 86.8503 4.5663C87.8487 2.72376 89.5383 1.5221 91.8423 0.881216C94.1463 0.240332 97.9095 0 103.055 0H104.207C108.354 0 111.426 0.240332 113.577 0.640884C115.727 1.12155 117.263 1.92265 118.185 3.20442C119.106 4.40608 119.644 6.32873 119.644 8.81215C119.644 10.174 119.49 11.9365 119.183 14.0193L119.03 15.3812C118.492 19.6271 117.647 22.6713 116.649 24.4337C115.574 26.2762 113.961 27.4779 111.657 28.1188C109.353 28.7597 105.59 29 100.444 29H99.2919C95.1447 29 91.9959 28.7597 89.8455 28.3591ZM100.905 22.7514C103.977 22.7514 106.127 22.5912 107.356 22.1906C108.662 21.8702 109.506 21.1492 110.044 20.1077C110.582 19.0663 110.966 17.3039 111.273 14.7403L111.426 13.6188C111.58 12.0967 111.734 10.9751 111.734 10.2541C111.734 8.97238 111.503 8.01105 110.966 7.37017C110.505 6.80939 109.66 6.40884 108.508 6.16851C107.356 6.00829 105.59 5.92818 103.132 5.92818H102.441C99.3687 5.92818 97.2183 6.0884 95.9895 6.48895C94.6839 6.80939 93.8391 7.53039 93.3015 8.57182C92.7639 9.61326 92.3799 11.4558 91.9959 14.0193L91.8423 15.1409C91.6887 16.663 91.5351 17.7845 91.5351 18.5055C91.5351 19.7873 91.7655 20.7486 92.3031 21.3094C92.7639 21.8702 93.6087 22.2707 94.7607 22.4309C95.9127 22.5912 97.6791 22.6713 100.06 22.6713H100.905V22.7514Z"
      />
      <path
        fill="black"
        d="M126.294 0H146.664C149.029 0 150.936 0.159006 152.386 0.55652C153.759 0.954034 154.827 1.51056 155.437 2.30558C156.048 3.10061 156.429 4.29316 156.429 5.6447C156.429 6.20122 156.353 6.75774 156.277 7.39377C156.124 8.66581 155.819 9.69935 155.437 10.4944C155.056 11.2894 154.522 11.9254 153.912 12.4024C153.301 12.8795 152.462 13.1975 151.546 13.436V13.5155C152.843 13.754 153.835 14.231 154.446 15.1055C155.056 15.9801 155.437 17.0931 155.437 18.6832C155.437 19.2397 155.361 19.7962 155.285 20.4322C155.056 22.4198 154.522 24.0099 153.683 25.1229C152.92 26.2359 151.775 27.031 150.402 27.4285C149.029 27.9055 147.198 28.0645 144.909 28.0645H122.403L126.294 0ZM144.222 22.4993C145.443 22.4993 146.359 22.2608 146.969 21.7838C147.503 21.3068 147.884 20.5117 148.037 19.4782C148.113 19.0012 148.113 18.7627 148.113 18.6037C148.113 17.8086 147.884 17.2521 147.427 16.9341C146.969 16.6161 146.206 16.4571 145.214 16.4571H131.634L130.871 22.5788H144.222V22.4993ZM145.596 11.2099C146.511 11.2099 147.274 10.9714 147.732 10.5739C148.19 10.1764 148.571 9.46084 148.724 8.50681C148.8 8.10929 148.8 7.79128 148.8 7.55277C148.8 6.75775 148.571 6.28073 148.113 5.96272C147.656 5.72421 146.969 5.5652 145.977 5.5652H133.007L132.245 11.2099H145.596Z"
      />
      <path
        fill="black"
        d="M166.175 28.3591C164.025 27.8785 162.489 27.0773 161.567 25.7956C160.646 24.5138 160.108 22.6713 160.108 20.1878C160.108 18.826 160.262 17.0635 160.569 14.9807L160.799 13.6188C161.337 9.37293 162.182 6.32873 163.18 4.5663C164.179 2.72376 165.868 1.5221 168.172 0.881216C170.476 0.240332 174.239 0 179.385 0H180.537C184.684 0 187.756 0.240332 189.907 0.640884C192.057 1.12155 193.593 1.92265 194.515 3.20442C195.436 4.40608 195.974 6.32873 195.974 8.81215C195.974 10.174 195.82 11.9365 195.513 14.0193L195.359 15.3812C194.822 19.6271 193.977 22.6713 192.979 24.4337C191.903 26.2762 190.291 27.4779 187.987 28.1188C185.683 28.7597 181.919 29 176.774 29H175.622C171.475 29 168.326 28.7597 166.175 28.3591ZM177.235 22.7514C180.307 22.7514 182.457 22.5912 183.686 22.1906C184.991 21.8702 185.836 21.1492 186.374 20.1077C186.911 19.0663 187.295 17.3039 187.603 14.7403L187.756 13.6188C187.91 12.0967 188.063 10.9751 188.063 10.2541C188.063 8.97238 187.833 8.01105 187.295 7.37017C186.835 6.80939 185.99 6.40884 184.838 6.16851C183.686 6.00829 181.919 5.92818 179.462 5.92818H178.771C175.699 5.92818 173.548 6.0884 172.319 6.48895C171.014 6.80939 170.169 7.53039 169.631 8.57182C169.094 9.61326 168.71 11.4558 168.326 14.0193L168.172 15.1409C168.019 16.663 167.865 17.7845 167.865 18.5055C167.865 19.7873 168.095 20.7486 168.633 21.3094C169.094 21.8702 169.939 22.2707 171.091 22.4309C172.243 22.5912 174.009 22.6713 176.39 22.6713H177.235V22.7514Z"
      />
      <path
        fill="black"
        d="M206.961 28.0645L210.017 6.08723H198.732L199.516 0H230L229.138 6.08723H217.853L214.797 28.0645H206.961Z"
      />
      <path
        fill="#ED1C24"
        d="M9.95029 6.24707H2.17663L0 22.0624H7.77366L9.95029 6.24707Z"
      />
      <path
        fill="black"
        d="M42.5221 3.32132C41.5892 2.13517 40.0345 1.3444 37.9356 0.94902C35.8367 0.553636 32.6495 0.316406 28.5295 0.316406H10.8055L9.95043 6.32623H17.7241H27.208C29.6955 6.32623 31.4835 6.40531 32.6495 6.56346C33.8156 6.72161 34.6707 7.117 35.2148 7.74961C35.759 8.38222 35.9922 9.33114 35.9922 10.5964C35.9922 11.3871 35.9145 12.3361 35.759 13.4431L35.6035 14.5502C35.2926 16.8434 34.8262 18.504 34.282 19.532C33.7378 20.56 32.805 21.2717 31.4057 21.588C30.0842 21.9834 27.8299 22.1416 24.8759 22.1416H15.5475H7.7738L6.9187 28.3886H24.7981C30.0065 28.3886 33.7378 28.0723 36.0699 27.5188C38.402 26.8862 40.0345 25.7791 40.9674 24.0394C41.9779 22.2997 42.6776 19.3739 43.2995 15.341L43.5327 13.8385C43.7659 12.0197 43.9213 10.3591 43.9213 8.85668C43.9213 6.32623 43.4549 4.50747 42.5221 3.32132Z"
      />
    </Icon>
  )
}
