import * as React from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Link
} from "@chakra-ui/react";


export const CallToAction: React.FC = () => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      py="100px"
    >
      <Box
        w={{
          base: '100%',
          md: '595px'
        }}
      >
        <Heading
          as="h2"
          fontSize="48px"
          fontWeight={ 700 }
          textAlign="center"
        >
          Schedule Your Free Estimate Today.
        </Heading>
        <Flex
          justifyContent="center"
          alignItems="center"
          mt="32px"
        >
          <Box>
            <Button
              as={ Link }
              href="tel:+16477216065"
              isExternal
              variant="solid"
              h="42px"
              bg="primary.900"
              border="1px"
              borderRadius="21px"
              borderColor="primary.900"
              color="white"
              fontWeight={ 500 }
              _hover={{
                bg: "transparent",
                color: "primary.900",
                textDecoration: "none"
              }}
            >
              +1 647 721 6065
            </Button>
          </Box>
        </Flex>
      </Box>
    </Flex>
  )
}
