import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: object | string
  h?: object | string
  color?: string
}


export const WaterproofIcon: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 45 39" { ...props }>
      <path
        fill="transparent"
        d="M0 23.2698V1H23.1152C22.1142 2.01186 21.0885 2.99898 20.1182 4.03912C17.891 6.42516 15.8001 8.92433 14.1829 11.784C13.0509 13.7866 12.3214 15.8651 12.636 18.2529C13.2592 22.9817 17.2151 26.9752 21.9718 27.3923C26.528 27.7918 30.0384 25.9536 32.3745 22.0016C33.4758 20.137 33.939 18.0779 33.6533 15.904C33.4529 14.3778 32.7973 13.0098 32.0432 11.693C30.3961 8.8174 28.2797 6.30233 26.0288 3.89772C25.1086 2.90619 24.1462 1.96237 23.2031 1H45V30.0744C44.8989 30.0841 44.797 30.1018 44.6959 30.1018C41.2102 30.1018 37.7238 30.1018 34.2369 30.1018C34.1446 30.1018 34.0515 30.0859 33.8572 30.0682C34.0523 29.9436 34.1552 29.872 34.2633 29.8093C36.6891 28.4077 38.8415 26.658 40.5229 24.4115C41.8298 22.6635 42.9645 20.7883 44.173 18.9616C44.3813 18.647 44.5746 18.3218 44.7961 17.9683L42.5663 16.5385C42.467 16.686 42.3747 16.816 42.2895 16.9503C41.4642 18.2529 40.6661 19.574 39.8109 20.8554C35.2626 27.6707 28.8853 30.7284 20.7853 29.9578C16.461 29.5442 12.7195 27.6716 9.55283 24.6652C7.9418 23.1363 6.69023 21.3362 5.54326 19.3743L8.64756 20.0106L9.1793 17.393L1.59258 15.8094C1.25771 17.4354 0.944824 18.9908 0.616113 20.5391C0.422754 21.452 0.206543 22.3595 0 23.2698Z"
      />
      <path
        fill="currentColor"
        d="M0 23.2697C0.206543 22.3595 0.422754 21.4519 0.615234 20.539C0.943945 18.989 1.25684 17.4354 1.5917 15.8093L9.18106 17.3885L8.64932 20.0061L5.54502 19.3698C6.69199 21.3317 7.94356 23.1318 9.55459 24.6607C12.7187 27.6653 16.4628 29.5397 20.787 29.9533C28.8879 30.7283 35.2652 27.6706 39.8145 20.8554C40.6696 19.574 41.4677 18.2528 42.293 16.9502C42.3809 16.8159 42.4687 16.6851 42.5698 16.5384L44.7987 17.9674C44.5772 18.3209 44.3839 18.6461 44.1756 18.9607C42.9671 20.7838 41.8324 22.6626 40.5255 24.4106C38.8477 26.6579 36.6917 28.4077 34.2659 29.8092C34.1578 29.872 34.055 29.9436 33.8599 30.0682C34.0541 30.0858 34.1473 30.1017 34.2396 30.1017C37.7253 30.1017 41.2116 30.1017 44.6985 30.1017C44.7996 30.1017 44.9016 30.0841 45.0026 30.0744V38.9999H0V30.0744C0.0713922 30.0883 0.14362 30.0974 0.216211 30.1017C4.18066 30.1017 8.14482 30.1017 12.1087 30.1017C12.2196 30.0902 12.3297 30.0716 12.4383 30.0461C8.46123 27.8447 5.52393 24.7323 3.29238 20.7856L2.61914 23.982L0 23.4464V23.2697ZM14.9414 32.8218C14.6558 33.976 14.371 35.1248 14.0783 36.3081H17.5676C17.855 35.1522 18.1397 34.0025 18.4324 32.8218H14.9414ZM21.1816 32.8165C20.8951 33.9777 20.6104 35.1319 20.3186 36.3161H23.8096C24.097 35.1531 24.3826 33.9981 24.6735 32.8165H21.1816ZM30.0445 36.3161C30.3311 35.1584 30.6176 34.0025 30.9094 32.8263H27.4219L26.5623 36.3161H30.0445ZM37.1584 32.8139H33.6621C33.3756 33.9724 33.0891 35.1283 32.7955 36.3152H36.2821C36.573 35.1646 36.8587 34.0149 37.1584 32.8139ZM7.95322 36.3055H11.3379C11.6271 35.1398 11.9101 33.9937 12.1983 32.8271H8.81279L7.95322 36.3055ZM2.66045 36.3311C3.46641 36.3311 4.2249 36.3364 4.98252 36.3231C5.03569 36.3165 5.08644 36.2969 5.13039 36.2662C5.17435 36.2354 5.21017 36.1943 5.23477 36.1464C5.52041 35.0462 5.78936 33.9424 6.0627 32.8351H2.66045V36.3311ZM42.3308 32.8192H39.9023L39.0375 36.3055H42.329L42.3308 32.8192Z"
      />
      <path
         fill="currentColor"
        d="M23.2031 0.999897C24.1461 1.96227 25.1085 2.90608 26.0287 3.88966C28.2796 6.29515 30.396 8.80934 32.0431 11.685C32.7972 13.0017 33.4493 14.3697 33.6533 15.8959C33.9389 18.0734 33.4775 20.1325 32.3744 21.9936C30.0383 25.95 26.528 27.7881 21.9717 27.3843C17.2151 26.9672 13.2591 22.9736 12.636 18.2448C12.3213 15.8588 13.0508 13.7785 14.1829 11.776C15.8 8.91627 17.891 6.41799 20.1181 4.03106C21.0849 2.99092 22.1141 2.0038 23.1152 0.991943L23.2031 0.999897ZM23.1152 4.65497C22.9798 4.83171 22.891 4.96869 22.7847 5.08799C21.7511 6.25185 20.6841 7.38566 19.6874 8.57957C18.2074 10.347 16.8556 12.2134 15.8721 14.3238C15.3114 15.5265 15.0723 16.7637 15.2991 18.0928C16.0716 22.6316 20.3677 25.5541 24.8791 24.5997C29.3906 23.6452 32.7585 18.2112 30.099 13.6468C28.8408 11.49 27.3855 9.4557 25.7519 7.57036C24.9169 6.60711 24.0433 5.68362 23.1152 4.65497Z"
      />
      <path
        fill="transparent"
        d="M0 23.4465L2.61914 23.9821L3.29238 20.7856C5.52393 24.7323 8.46123 27.8448 12.4383 30.0462C12.3297 30.0717 12.2196 30.0903 12.1087 30.1018C8.14482 30.1018 4.18066 30.1018 0.216211 30.1018C0.14362 30.0975 0.0713922 30.0883 0 30.0744V23.4465Z"
      />
      <path
        fill="transparent"
        d="M14.9415 32.8218H18.4325C18.1398 34.0024 17.855 35.1521 17.5676 36.3081H14.0784C14.3746 35.1248 14.6593 33.9759 14.9415 32.8218Z"
      />
      <path
        fill="transparent"
        d="M21.1816 32.8137H24.67C24.3791 33.9953 24.0934 35.1503 23.806 36.3133H20.322C20.6138 35.1317 20.8986 33.9776 21.1816 32.8137Z"
      />
      <path
        fill="transparent"
        d="M30.0481 36.316H26.5615L27.422 32.8262H30.9139C30.6212 34.0024 30.3347 35.1583 30.0481 36.316Z"
      />
      <path
        fill="transparent"
        d="M37.1582 32.8137C36.8585 34.0129 36.5729 35.1627 36.2855 36.315H32.7988C33.0924 35.1282 33.3789 33.9723 33.6654 32.8137H37.1582Z"
      />
      <path
        fill="transparent"
        d="M7.95312 36.3055L8.8127 32.8271H12.1938C11.9056 33.9937 11.6226 35.1398 11.3334 36.3055H7.95312Z"
      />
      <path
        fill="transparent"
        d="M2.6604 36.3311V32.8386H6.0644C5.79106 33.9459 5.52212 35.0479 5.23647 36.1499C5.21188 36.1978 5.17606 36.2389 5.1321 36.2697C5.08815 36.3005 5.03739 36.32 4.98423 36.3267C4.22485 36.3364 3.46636 36.3311 2.6604 36.3311Z"
      />
      <path
        fill="transparent"
        d="M42.3306 32.8193V36.3056H39.0391L39.9021 32.8193H42.3306Z"
      />
      <path
        fill="transparent"
        d="M23.1152 4.65503C24.0424 5.67926 24.9161 6.60717 25.7519 7.57131C27.386 9.45601 28.8419 11.4897 30.1007 13.646C32.7603 18.2104 29.3976 23.6427 24.8809 24.5988C20.3642 25.555 16.0743 22.6308 15.3008 18.092C15.0741 16.7664 15.3131 15.5292 15.8739 14.3229C16.8574 12.2144 18.2091 10.3515 19.6892 8.57875C20.6859 7.38573 21.7529 6.25103 22.7865 5.08717C22.8919 4.96875 22.9807 4.83354 23.1152 4.65503Z"
      />
    </Icon>
  )
}
