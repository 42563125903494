import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: object | string
  h?: object | string
  color?: string
}


export const ConcreteTruckIcon: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="2 2 47 47" fill="none" { ...props }>
      <path
        stroke="currentColor"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.1562 46.2188C14.6771 46.2188 17.5312 43.3646 17.5312 39.8438C17.5312 36.3229 14.6771 33.4688 11.1562 33.4688C7.63543 33.4688 4.78125 36.3229 4.78125 39.8438C4.78125 43.3646 7.63543 46.2188 11.1562 46.2188Z"
      />
      <path
        stroke="currentColor"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M33.4688 46.2188C36.9896 46.2188 39.8438 43.3646 39.8438 39.8438C39.8438 36.3229 36.9896 33.4688 33.4688 33.4688C29.9479 33.4688 27.0938 36.3229 27.0938 39.8438C27.0938 43.3646 29.9479 46.2188 33.4688 46.2188Z"
      />
      <path
        stroke="currentColor"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.7344 36.6562H27.8906"
      />
      <path
        stroke="currentColor"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M39.6844 38.25H46.2188V28.6875L39.8438 19.125H31.875V33.6281"
      />
      <path
        stroke="currentColor"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M31.875 25.5H36.6562C37.6125 27.4125 39.6844 28.6875 41.7563 28.6875H46.2188"
      />
      <path
        stroke="currentColor"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M27.0938 19.4439C25.3406 16.8939 22.1531 14.5032 18.3281 13.0689C14.5031 11.6345 10.6781 10.8376 7.49062 11.4751L3.1875 22.1532C4.94063 24.7032 8.12812 27.0939 11.9531 28.5282C15.7781 30.122 19.6031 30.6001 22.7906 29.9626L27.0938 19.4439Z"
      />
      <path
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.1844 12.75L8.76562 25.8188"
      />
      <path
        stroke="currentColor"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.96875 27.0938V33.4688"
      />
      <path
        stroke="currentColor"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M22.3125 30.2812V36.6562"
      />
    </Icon>
  )
}
