import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface FacebookIconProps {}


export const FacebookIcon: React.FC<FacebookIconProps> = ( props ) => {
  return (
    <Icon viewBox="0 0 6 12" { ...props }>
      <path
        fill="currentColor"
        d="M5.04603 2.05858H6V0H4.56903C2.56067 0 1.58159 1.20502 1.58159 2.91214V4.54394H0V6.50209H1.58159V11.4728H3.94142V6.50209H5.52301L5.97489 4.54394H3.94142V3.21339C3.94142 2.63599 4.24268 2.05858 5.04603 2.05858Z"
      />
    </Icon>
  )
}
