import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  Box,
  Heading,
  Image,
  Text
} from "@chakra-ui/react";

import { IGatsbyImageData } from "gatsby-plugin-image";


interface Props {
  heading: string
  description?: React.ReactNode | string | undefined
  image?: IGatsbyImageData | undefined
  alignment: "left" | "center"

  spacing?: "large" | "small" | undefined
  divider?: boolean | undefined

  HeroComponent?: React.ReactNode | undefined
}


export const PageHero: React.FC<Props> = ( props ) => {
  return (
    <Box
      position="relative"
      w="100%"
      pt={{
        lg: '168px'
      }}
    >
      <Heading
        as="h1"
        maxW="505px"
        m={ props.alignment === "center" ? "0 auto" : "0" }
        fontSize="48px"
        fontWeight={ 700 }
        textAlign={ props.alignment }
      >
        { props.heading }
      </Heading>
      { props.image &&
        <Box
          position={{
            base: 'static',
            lg: 'absolute'
          }}
          right={ 0 }
          top={{
            lg: '50%'
          }}
          w={{
            base: '100%',
            lg: '450px',
            xl: '550px'
          }}
          h="auto"
          mt={{
            base: '48px',
            lg: '0'
          }}
          transform={{
            lg: 'translateY( -30% )'
          }}
        >
          <Image
            as={ GatsbyImage }
            image={ props.image }
          />
        </Box>
      }
      { props.description &&
        <Box
          w="100%"
          mt={ props.spacing === "large" ? {
            base: '48px',
            lg: '122px'
          } : {
            base: '0'
          }}
          pt="24px"
          borderTop={ props.divider ? '1px' : '0' }
          borderColor="primary.900"
        >
          <Text
            maxW="600px"
            w={{
              lg: '500px',
              xl: '100%'
            }}
            m={ props.alignment === "center" ? "0 auto" : "0" }
            fontSize="18px"
            fontWeight={ 400 }
            textAlign={ props.alignment === "center" ? 'center' : 'left' }
          >
            { props.description }
          </Text>
        </Box>
      }
      { props.HeroComponent &&
        <Box
          mt={{
            base: '40px',
            lg: '120px'
          }}
        >
          { props.HeroComponent }
        </Box>
      }
    </Box>
  )
}
