import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: object | string
  h?: object | string
  color?: string
}


export const BurgerIcon: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 14 12" { ...props }>
      <path
        fill="currentColor"
        d="M0.333984 1.99984H13.6673V0.666504H0.333984V1.99984Z"
      />
      <path
        fill="currentColor"
        d="M13.6673 6.6665H0.333984V5.33317H13.6673V6.6665Z"
      />
      <path
        fill="currentColor"
        d="M13.6673 11.3332H0.333984V9.99984H13.6673V11.3332Z"
      />
    </Icon>
  )
}
