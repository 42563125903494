import * as React from "react";
import {
  Flex,
  IconButton,
  Link
} from "@chakra-ui/react";

import {
  FacebookIcon,
  InstagramIcon
} from "../ScalableVectorGraphics";


export const Socials: React.FC = () => {
  return (
    <Flex
      justifyContent="flex-start"
      alignItems="center"
      gap="16px"
    >
      <IconButton
        aria-label="Instagram"
        as={ Link }
        href="https://www.instagram.com/drobotconstruction/"
        isExternal
        icon={ <InstagramIcon /> }
        minW="32px"
        w={{
          base: '32px',
          lg: '40px'
        }}
        h={{
          base: '32px',
          lg: '40px'
        }}
        bg="transparent"
        border="1px"
        borderRadius="50%"
        borderColor="primary.900"
        color="primary.900"
        transition="all 0.3s ease-in-out"
        _hover={{
          bg: "primary.900",
          color: "white"
        }}
      />
      <IconButton
        aria-label="Facebook"
        as={ Link }
        href="https://www.facebook.com/pages/category/Construction-Company/Drobot-Construction-114084633641005/"
        isExternal
        icon={ <FacebookIcon /> }
        minW="32px"
        w={{
          base: '32px',
          lg: '40px'
        }}
        h={{
          base: '32px',
          lg: '40px'
        }}
        bg="transparent"
        border="1px"
        borderRadius="50%"
        borderColor="primary.900"
        color="primary.900"
        transition="all 0.3s ease-in-out"
        _hover={{
          bg: "primary.900",
          color: "white"
        }}
      />
    </Flex>
  )
}
