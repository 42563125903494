import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: object | string
  h?: object | string
  color?: string
}


export const ChatIcon: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 18 16" { ...props }>
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.998 7.513C1.998 4.475 5.139 2.003 9 2.003C12.861 2.003 16.002 4.475 16.002 7.513C16.002 10.552 12.861 13.023 9 13.023C5.139 13.023 1.998 10.552 1.998 7.513ZM16.838 15.284V15.282C16.838 15.282 15.274 13.022 16.071 12.166L16.034 12.186C17.261 10.902 18 9.279 18 7.513C18 3.371 13.963 0 9 0C4.037 0 0 3.37 0 7.513C0 11.656 4.037 15.027 9 15.027C10.42 15.027 11.76 14.742 12.957 14.251C13.96 15.273 15.244 15.823 16.197 15.97L16.199 15.967C16.2515 15.9867 16.3069 15.9979 16.363 16C16.448 16 16.5317 15.9789 16.6067 15.9386C16.6816 15.8984 16.7453 15.8402 16.7923 15.7693C16.8392 15.6984 16.8679 15.6169 16.8757 15.5323C16.8835 15.4476 16.8712 15.3623 16.838 15.284Z"
      />
      <path
        fill="currentColor"
        d="M13 5H5C4.44772 5 4 5.44772 4 6C4 6.55228 4.44772 7 5 7H13C13.5523 7 14 6.55228 14 6C14 5.44772 13.5523 5 13 5Z"
      />
      <path
        fill="currentColor"
        d="M8 8H5C4.44772 8 4 8.44771 4 9C4 9.55228 4.44772 10 5 10H8C8.55228 10 9 9.55228 9 9C9 8.44771 8.55228 8 8 8Z"
      />
    </Icon>
  )
}
