/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql, StaticQuery } from "gatsby";


type StaticQueryData = {
  site: {
    siteMetadata: {
      title: string
      description: string
      author: string
    }
  }
}


interface HeadProps {
  readonly title: string
  readonly description?: string
  readonly lang?: string
};


const Head: React.FC<HeadProps> = ({
  description,
  lang,
  title
}) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `}

    render={( data: StaticQueryData ): React.ReactElement | null => {
      const metaDescription = description || data.site.siteMetadata.description;
      const defaultTitle = data.site.siteMetadata?.title;

      return (
        <Helmet
          htmlAttributes={{
            lang,
          }}
          title={ title }
          titleTemplate={ defaultTitle ? `%s | ${ defaultTitle }` : null }
        >
          <meta name="description" content={ metaDescription } />
          <meta property="og:title" content={ title } />
          <meta property="og:description" content={ metaDescription } />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:creater" content={ data.site.siteMetadata?.author || `` } />
          <meta name="twitter:title" content={ title } />
          <meta name="twitter:description" content={ metaDescription } />
        </Helmet>
      )
    }}
  />
)


export default Head;
