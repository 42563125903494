import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
  Box,
  Button,
  Heading,
  VStack
} from "@chakra-ui/react";


interface Props {
  heading: string
  subheading: string
  children: React.ReactNode
}


export const IndexHero: React.FC<Props> = ( props ) => {
  return (
    <>
      <VStack
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={ 8 }
        w={{
          base: '100%',
          md: '680px'
        }}
      >
        <Heading
          as="h1"
          pt="82px"
          fontSize="48px"
        >
          { props.heading }
        </Heading>
        <Heading
          as="h6"
          fontSize="16px"
          fontWeight={ 400 }
        >
          { props.subheading }
        </Heading>
        <Box>
          <Button
            as={ GatsbyLink }
            to="/contact"
            variant="solid"
            h="42px"
            w="153px"
            background="primary.900"
            borderRadius="21px"
            borderColor="primary.900"
            color="white"
            fontSize="14px"
            fontWeight={ 500 }
          >
            Start A Project
          </Button>
        </Box>
      </VStack>
      <Box
        maxW="100%"
        w="100%"
        margin="80px 0 0"
        padding="0"
      >
        { props.children }
      </Box>
    </>
  )
}
