import * as React from "react";
import { PhoneIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Heading,
  Link,
  Text,
  VStack
} from "@chakra-ui/react";


export const CallNow: React.FC = () => {
  return (
    <>
      <Flex
        position="absolute"
        top="0"
        left="50%"
        justifyContent="center"
        alignItems="center"
        w="85px"
        h="85px"
        bg="primary.900"
        borderRadius="50%"
        transform="translate(-50%, -50%)"
      >
        <PhoneIcon
          w="auto"
          h="34px"
          color="white"
        />
      </Flex>
      <VStack
        spacing={ 4 }
        padding="85px 0 50px"
        textAlign="center"
      >
        <Heading
          as="h4"
          color="text.primary"
          fontSize="28px"
          fontWeight={ 600 }
        >
          Call Us Today
        </Heading>
        <Text
          color="text.primary"
          w={{
            base: "290px",
            lg: "320px"
          }}
          fontSize="16px"
          fontWeight={ 400 }
        >
          Got a question? The Drobot Construction team is here to help you. 
        </Text>
        <Box>
          <Button
            as={ Link }
            href="tel:+16477216065"
            variant="link"
            color="primary.900"
            fontSize={{
              base: "36px",
              lg: "48px"
            }}
            _hover={{
              opacity: '0.6',
              textDecoration: 'none'
            }}
            _focus={{
              boxShadow: 'none !important'
            }}
          >
            +1 647 721 6065
          </Button>
        </Box>
      </VStack>
    </>
  )
}
