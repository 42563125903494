import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
  LinkBox,
  LinkOverlay
} from "@chakra-ui/react";


interface NavItemProps {
  node: any
}


export const BoxItem: React.FC<NavItemProps> = ( props ) => {
  return (
    <LinkBox
      pt="1rem"
      borderTop="1px"
      borderColor="border.gainsboro"
      transition="all 0.3s ease-in-out"
      _hover={{
        borderColor: "primary.900",
        color: "primary.900"
      }}
    >
      <LinkOverlay
        as={ GatsbyLink }
        to={ props.node.path }
      >
        { props.node.label }
      </LinkOverlay>
    </LinkBox>
  )
}
