import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
  Button,
  GridItem
} from "@chakra-ui/react";


interface Props {
  node: any
}


export const ListItem: React.FC<Props> = ( props ) => {
  return (
    <GridItem>
      <Button
        as={ GatsbyLink }
        to={ props.node.path }
        variant="link"
        minW="0"
        color="text.primary"
        fontSize="18px"
        fontWeight={ 400 }
        _hover={{
          color: 'primary.900'
        }}
      >
        { props.node.label }
      </Button>
    </GridItem>
  )
}
