import * as React from "react";
import {
  Container
} from "@chakra-ui/react";


interface Props {
  children: React.ReactNode
}


export const SectionContainer: React.FC<Props> = ( props ) => {
  return (
    <Container
      maxW={{
        base: '100%',
        sm: '640px',
        md: '768px',
        lg: '1024px',
        xl: '1280px'
      }}
      w="100%"
      padding="0 1rem"
    >
      { props.children }
    </Container>
  )
}
