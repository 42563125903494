import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: object | string
  h?: object | string
  color?: string
}


export const ArrowRightIcon: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 8 14" { ...props }>
      <path
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        d="M1.33301 1.66675L6.66634 7.00008L1.33301 12.3334"
      />
    </Icon>
  )
}
